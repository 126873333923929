// jQueryEasing
jQuery.easing.jswing=jQuery.easing.easeOutExpo,jQuery.extend(jQuery.easing,{easeOutExpo:function(e,n,u,a,r){return n==r?u+a:a*(-Math.pow(2,-10*n/r)+1)+u}});

function socialYouTubeSet() {
  var source = "";
  
  var youtube = document.querySelectorAll(".yt");
  for (var i = 0; i < youtube.length; i++) {
      var source = "https://i.ytimg.com/vi/" + youtube[i].dataset.embed + "/hqdefault.jpg";

      var image = new Image();
      image.setAttribute("src", source);
      image.alt = youtube[i].dataset.title; 
      image.addEventListener("load", function () {
          youtube[i].appendChild(image);
      }(i)); 

      youtube[i].addEventListener("click", function () {

          var iframe = document.createElement("iframe");

          iframe.setAttribute("frameborder", "0");
          iframe.setAttribute("allowfullscreen", "");
          iframe.setAttribute("allow", "autoplay");
          iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?autoplay=1");
          this.innerHTML = "";
          this.appendChild(iframe);
      });
  };
} 
$(function() { socialYouTubeSet(); }); 

// Intersection Observer for animated sections 
function observeAnimate() {
    const SELECTOR = '.animate'; // watch selector
    const ANIMATE_CLASS_NAME = 'animated'; // class to add

    const animate = element => (
      element.classList.add(ANIMATE_CLASS_NAME)
    );

    const isAnimated = element => (
      element.classList.contains(ANIMATE_CLASS_NAME)
    );

    const intersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        
        // when element's is in viewport,
        // animate it!
        if (entry.intersectionRatio > 0) {
          animate(entry.target);
        }
        
        // remove observer after animation
        //observer.unobserve(entry.target); // Miro: if enabled, problem with multiple instances
      });
    });

    // get only these elements,
    // which are not animated yet
    const elements = [].filter.call(
      document.querySelectorAll(SELECTOR),
      element => !isAnimated(element, ANIMATE_CLASS_NAME),
    );

    // start observing your elements
    elements.forEach((element) => intersectionObserver.observe(element));
}
$(function() { observeAnimate(); }); 


//on document ready fire functions
$(function() {
  // https://github.com/metafizzy/isotope/issues/1447#issuecomment-429339948
  //jQueryBridget("isotope", isotope, $);

    // Show / hide gallery items
    var currentGalleryItem = "";
    $('.gallery-filter a').click(function(){
      currentGalleryItem = $(this).attr('data-item');
      $('.gallery-filter a').removeClass('current');
      $(this).addClass('current');
      hideGalleryItems();
    });
      
    function hideGalleryItems(){
      $('.photoGalleryInModal .galleryItem').hide();
      $('.photoGalleryInModal .galleryItem.'+currentGalleryItem).fadeTo(500, 1);
    }

    if($('.gallery-filter').length) {
      currentGalleryItem = $('.gallery-filter .current').attr('data-item');
     hideGalleryItems();
    }

  // SeaRay menu, append category to another category
  $(".main-nav .clear.Sdx").appendTo(".subNav.sub1 .flex .Sunsport");
  $(".main-nav .clear.Slx").appendTo(".subNav.sub1 .flex .Sundancer");

  // JS media queries
  enquire.register("screen and (max-width: 1023px)", [
    {
      match: function() {
        $("body").addClass("mobRes");
        $("body").removeClass("deskRes");
      }
    }
  ]);
  enquire.register("screen and (min-width: 1024px)", [
    {
      match: function() {
        $("body").removeClass("mobRes");
        $("body").addClass("deskRes");
      }
    }
  ]);
  enquire.register("screen and (max-width: 898px)", [
    {
      match: function() {
        $("body").addClass("tabletRes");
        createMenuCarousel();
      }
    }
  ]);
  enquire.register("screen and (min-width: 899px)", [
    {
      match: function() {
        $("body").removeClass("tabletRes");
        destroyMenuCarousel();
      }
    }
  ]);

  enquire.register("screen and (max-width: 550px)", [
    {
      match: function() {
        $("body").addClass("ultraMobRes");
      }
    }
  ]);
  enquire.register("screen and (min-width: 551px)", [
    {
      match: function() {
        $("body").removeClass("ultraMobRes");
      }
    }
  ]);

  // if iPad...
  var isiPad = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) != null;
  if (isiPad == true) {
    $("body").addClass("ipad");
  }

  // Lazy load
  $(".lazyload, .lazy, .textcontainer img, .size-thumbnail").lazyload({
    effect: "fadeIn",
    threshold: 0
  });

  var md5 = "df4c5044cb1730ceca33850dffdc649d",
    key = "fonts",
    cache;

  function insertFont(value) {
    var style = document.createElement("style");
    style.innerHTML = value;
    document.head.appendChild(style);
  }

  // PRE-RENDER
  try {
    cache = window.localStorage.getItem(key);
    if (cache) {
      cache = JSON.parse(cache);
      if (cache.md5 == md5) {
        insertFont(cache.value);
      } else {
        // Busting cache when md5 doesn't match
        window.localStorage.removeItem(key);
        cache = null;
      }
    }
  } catch (e) {
    // Most likely LocalStorage disabled
    return;
  }

  // POST-RENDER
  if (!cache) {
    // Fonts not in LocalStorage or md5 did not match
    window.addEventListener("load", function() {
      var request = new XMLHttpRequest(),
        response;
      request.open("GET", themeUri + "/assets/font/fonts.json", true);
      request.onload = function() {
        if (this.status == 200) {
          try {
            response = JSON.parse(this.response);
            insertFont(response.value);
            window.localStorage.setItem(key, this.response);
          } catch (e) {
            // LocalStorage is probably full
          }
        }
      };
      request.send();
    });
  }

  // Modal
  $(".download.brochureDownload").click(function() {
    $(".cover.brochureDownloadModal").fadeIn();
    //$(this).preventDefault();
  }); 

  $(".download.offerDownload").click(function() {
    $(".cover.offerDownload").fadeIn();
    //$(this).preventDefault();
  });

  // On escape close modal
  $(document).keyup(function(e) {
    if (e.keyCode === 27)
      $(".cover.brochureDownloadModal, .cover.offerDownload").fadeOut();
  });

  $(".closeModal, .closeCover").click(function() {
    $(".cover.brochureDownloadModal, .cover.offerDownload").fadeOut();
  });

  //Isotope grid
  /* var $container = $("article .gallery"); */
  /* $container.isotope({
    itemSelector: ".gallery-item",
    masonry: { columnWidth: $container.width() / 3 }
  });
 */
  //update columnWidth on window resize
/*   $(window).resize(function() {
    $container.isotope({
      // update columnWidth to a percentage of container width
      masonry: { columnWidth: $container.width() / 3 }
    });
  });

 */  // Filter gallery
  /* $('.gallery-filter a').click(function(){
    $('body').on('click', '.gallery-filter a', function () {
    var selector = $(this).attr('data-filter');
    $('.grid-item').hide();
    $('.grid-item' + selector).show();
   */
/*     var $container = $('.isotope');
      $container.isotope({
      masonry: { columnWidth: $container.width() / 4 }
      });
  // //$container.isotope({ filter: selector });
 */
  /* $('.gallery-filter a').removeClass('checked');
  $(this).addClass('checked');
   return false;
   }); */
/* }); */

  // MegaMenu
  var currentMenuItem = "";

  function hideMenuItems() {
    $(".subNav").removeClass("animate");
    $(".subNav").addClass("hideanimate");
    $(".subNav").hide();

    if (currentMenuItem != "") {
      $(".subNav." + currentMenuItem).show();
      $(".subNav." + currentMenuItem).addClass("animate");
    }
  }

  $(".main-nav .hasSub").hover(function() {
    currentMenuItem = $(this).attr("data-item");
    //console.log(currentMenuItem);
    $(".main-nav .hasSub").removeClass("current");
    $(this).addClass("current");
    hideMenuItems();
  });

  $(".main-nav > a:not(.hasSub)").hover(function() {
    currentMenuItem = "";
    $(".main-nav .hasSub").removeClass("current");
    hideMenuItems();
  });

  $(".subNav").mouseleave(function() {
    currentMenuItem = "";
    $(".main-nav .hasSub").removeClass("current");
    hideMenuItems();
  });

  $(document).mouseup(function(e) {
    if (
      !$(".main-nav").is(e.target) && // if the target of the click isn't the container...
      $(".main-nav").has(e.target).length === 0
    ) {
      // ... nor a descendant of the container
      currentMenuItem = "";
      $(".main-nav .hasSub").removeClass("current");
      hideMenuItems();
    }
  });

  $("a[data-item=sub1]").click(function() {
    //$('.sub1 .after').delay( 100 ).fadeOut( 1200 );
    $(".sub1 .flex").fadeTo(0, 0);
    $(".sub1 .flex")
      .delay(200)
      .fadeTo(1000, 1);
  });

  // echo problem
  if ($('html').attr('lang') === 'hr-HR' && $('.readMore').length) {
      $('.readMore a').html('Pročitaj više');
  }

  // Mobile MegaMenu slider
  function createMenuCarousel() {
    $("body").addClass("megaMenuMobile");
    if ($(".site").hasClass("lagoon")) {
      $(".sub1 .flex").owlCarousel({
        loop: false,
        nav: true,
        navText: ["", ""],
        autoplay: false,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          360: {
            items: 2
          },
          700: {
            items: 4
          }
        }
      });
    } else if ($(".site").hasClass("searay")) {
      $(".sub1 .flex").owlCarousel({
        loop: false,
        nav: true,
        navText: ["", ""],
        autoplay: false,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          360: {
            items: 1
          },
          700: {
            items: 3
          }
        }
      });
      $(".sub1-2 .flex").owlCarousel({
        loop: false,
        nav: true,
        navText: ["", ""],
        autoplay: false,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
          360: {
            items: 1
          },
          700: {
            items: 3
          }
        }
      });
    } else {
      $(".sub1 .flex").owlCarousel({
        loop: false,
        nav: true,
        navText: ["", ""],
        autoplay: false,
        dots: false,
        responsive: {
          0: {
            items: 1
          }
        }
      });
    }
  }

  function paddingHeaderContent() {

    if ($(window).width() > 1240 && $('.page-header.slider').length) {
       var windowWidth = $(window).width();
       var sliderContentPaddingAmount = (windowWidth - 1200)/2;
       $('.page-header.slider .sliderText').css('cssText', 'padding-left: ' + sliderContentPaddingAmount + 'px !important');
    } else if($(window).width() <= 1240 && $('.page-header.slider').length) {
      var sliderContentPaddingAmount = 20;
      $('.page-header.slider .sliderText').css('padding-left', sliderContentPaddingAmount+"px");
    }
  }
  if ($(".site").hasClass("xyachts")) {
    paddingHeaderContent();
  }

  function destroyMenuCarousel() {
    $("body").removeClass("megaMenuMobile");
    $(".subNav .flex").owlCarousel("destroy");
  }

  // CAT tabs
  var currentItem = "t1"; // Prvi je aktivan
  var currentItemText = $(".tabs a:first-child").text();
  setActionSetValues();

  $(".cat-box .tabs a").click(function() {
    $(".cat-box").removeClass(currentItem);
    $(".tabs a").removeClass("current");
    $(this).addClass("current");
    currentItem = $(this).attr("data-item");
    currentItemText = $(this).text();
    setActionSetValues();
  });

  function setActionSetValues() {
    $(".cat-box").addClass(currentItem);
    //$('.actionTitle').html(currentItemText);
    $('input[name="interes"]').attr("value", currentItemText);
  }

  // add antispam field
  /* if ($("#leads").length > 0) {
    $(".formWrapperLeft").append(
      '<input type="text" name="ContactCheckAdd" value="">'
    );
    $("#brochure").append(
      '<input type="text" name="ContactCheckAdd" value="">'
    );
    $("#offer").append('<input type="text" name="ContactCheckAdd" value="">');
  } */

  $(".phone").hide();
  $("a.sPhone").click(function() {
    $(".phone").show();
    $("a.sPhone").hide();
    return false;
  });

  $(".email").hide();
  $("a.sEmail").click(function() {
    $(".email").show();
    $("a.sEmail").hide();
    return false;
  });

  //  Slider
  // Sort random function
  // function random(owlSelector) {
  //     owlSelector.children().sort(function () {
  //         return Math.round(Math.random()) - 0.5;
  //     }).each(function () {
  //         $(this).appendTo(owlSelector);
  //     });
  // }

  $(".slider").owlCarousel({
    //loop: true,
    nav: false,
    lazyLoad: true,
    autoplay: false,

    // beforeInit: function (elem) {
    //     random(elem);
    // },
    responsive: {
      0: {
        items: 1
      }
    }
  });

  $(".offer-slider").owlCarousel({
    loop: true,
    nav: false,
    lazyLoad: true,
    autoplay: true,
    center: true,

    responsive: {
      0: {
        items: 1
      }
    }
  });

  // News slider on X-Yachts home
  $(".newsSlider").owlCarousel({
    //loop: true,
    nav: true,
    dots: false,
    lazyLoad: true,
    autoplay: false,
    center: true,
    margin: 30,
    navText: "",
    responsiveClass: true,

    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      960: {
        items: 3
      },
      1200: {
        items: 4
      },
      1500: {
        items: 5
      }
    }
  });


  //Scroll to
  $("a[href*=\\#]:not([href=\\#])").click(function() {
    if (
      location.pathname.replace(/^\//, "") ===
        this.pathname.replace(/^\//, "") &&
      location.hostname === this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html,body").animate(
          {
            scrollTop: target.offset().top - 50
          },
          900,
          "easeOutExpo",
          function() {
            $(".nav").css("top", -107); /* Hide mainmenu when scrolled up */
          }
        );
        currentItem = "";
        return false;
      }
    }
  });

  // ToTop
  /*$('.totop').click(function(){
        $('html, body').animate({
            scrollTop: 0
        }, 500)
    });*/

  // Menu
  /*$('.navTrigger').click(function(){
        $('.nav').addClass('open');
    });
    $('.closeMenu').click(function(){
        $('.nav').removeClass('open');
    });*/

  // jsSocials
  /*$("#shareIcons").jsSocials({
        showLabel: false,
        showCount: false,
        shares: ["linkedin", "twitter", "facebook"],
        url: $(this).attr('data-url'),
        text: $(this).attr('data-title'),
       
    });*/

  // Window scroll function
  $(window).scroll(function () {
    if($('.header .page-nav').length) {
        var topBarHeight = $('.topBar').outerHeight();
        var logoLineHeight = $('.logoLine').outerHeight();
        var mainNavHeight = $('.main-nav').outerHeight();
        var headerHeight = $('.header').outerHeight();

        var heighSum = topBarHeight + logoLineHeight + mainNavHeight + headerHeight;

        if($('.header .page-nav').length) {
            var pageNavHight = $('.header .page-nav').outerHeight();
            heighSum -= pageNavHight;
        }

        var heighSumGalleryFilter = heighSum;

        if($('.boat-intro').length) {
            var introHeight = $('.boat-intro').outerHeight();
            heighSumGalleryFilter += introHeight;
        }

        if($('.boat-video').length) {
            var videoHeight = $('.boat-video').outerHeight();
            heighSumGalleryFilter += videoHeight;
        }
        
        if($('.boat-specification').length) {
            var specificationHeight = $('.boat-specification').outerHeight();
            heighSumGalleryFilter += specificationHeight;
        }

        if($('.boat-inventory').length) {
            var inventoryHeight = $('.boat-inventory').outerHeight();
            heighSumGalleryFilter += inventoryHeight;
        }

        if($('.boat-price-detail').length) {
            var priceHeight = $('.boat-price-detail').outerHeight();
            heighSumGalleryFilter += priceHeight;
        }

        if($('.extras').length) {
            var extrasHeight = $('.extras').outerHeight();
            heighSumGalleryFilter += extrasHeight;
        }
        
        if($('.devider').length) {
            var deviderHeight = $('.devider').outerHeight();
            heighSumGalleryFilter += deviderHeight;
        }
        
        if($('.gallery h1').length) {
            var galleryhHeight = $('.gallery h1').outerHeight();
            heighSumGalleryFilter += galleryhHeight;
        }
        

        var scrollTop = $(window).scrollTop();

        if(scrollTop >= heighSum){
            $('body').addClass("fixedBoatMenu");
            $('.gallery-filter').css('top', pageNavHight + "px")
        } else {
            $('body').removeClass("fixedBoatMenu");
        } 
    }

    // Parallax headera na detalju broda
    if ($('.boatdetail').length) {

      const 
        a = $(this).scrollTop(),
        b = 800;
        c = 50;
        buffer = 250;

      $(".header img").css({ 
        opacity: 1 - a / b 
      });

      $(".header h1").css({ 
        transform: "translate(-50%,-" + (c + (a / 0.8)) + "%)", 
        //opacity: 1 - a / b 
      });

      if(a > buffer) {
        $(".header .shadowText").css({ 
          transform: "translate(-50%,-" + (c + ((a-buffer) / 5.6)) + "%)"
        });
      }
    }

    if ($('.breadcrumb.post').length) {
        var topBarHeight = $('.topBar').outerHeight();
        var logoLineHeight = $('.logoLine').outerHeight();
        var mainNavHeight = $('.main-nav').outerHeight();
        // var headerHeight = $('.header').outerHeight();
        var pageNavHight = $('.single-featured-image-header').outerHeight();
        var heighSum = topBarHeight + logoLineHeight + mainNavHeight + pageNavHight;

        var scrollTop = $(window).scrollTop();

        if (scrollTop >= heighSum) {
            $('body').addClass("fixedBreadcrumbMenu");
        } else {
            $('body').removeClass("fixedBreadcrumbMenu");
        }
    }

});


  // Window resize function
  $(window).resize(function() {
    if ($(".site").hasClass("xyachts")) {
      paddingHeaderContent();
    }
    /*if($('.header').length) {
        }*/
  });
});

$(window).on("load", function(e) {
  $("#loader").fadeOut("fast", function() {
    $("#loader").hide();
  });

  //conversion tracking events on the page
  // mailto function
  // $('a[href^="mailto:"]').click(function () {
  /* $('a[class^="sEmail"]').click(function() {
    var emaillink = jQuery(this).attr("data-contact");
    gtag("event", "Click", {
      lang: lang,
      device: device,
      event_category: "Email",
      event_action: "Mailto",
      event_label: emaillink
    });
    return true;
  }); */

  // tel: function
  // $('a[href^="tel:"]').click(function () {
  /* $('a[class^="sPhone"]').click(function() {
    var telephonenumber = jQuery(this).attr("data-contact");
    gtag("event", "Click", {
      lang: lang,
      device: device,
      event_category: "Phone",
      event_action: "Call",
      event_label: telephonenumber
    });
    return true;
  });
 */
  // pdf function
   /*$('a[href$=".pdf"], a.brochureDownload, a.offerDownload').click(function() {
    var fileurl = jQuery(this).attr("href");
    var pdfurl = fileurl.substring(fileurl.lastIndexOf("/") + 1);
    gtag("event", "Click", {
      lang: lang,
      device: device,
      event_category: "PDF",
      event_action: "Download", 
      event_label: pdfurl
    });
    return true;
  });*/
 
  // register gtag event on load for tracking conversion
  /* var theurl = $(location).attr("pathname");
  if (theurl == "/thanks") {
    var interes = $("p[interes]").attr("interes");
    gtag("event", "Inquiry", {
      lang: lang,
      device: device,
      event_category: "Inquiry",
      event_action: "Interes",
      event_label: interes
    });
  } */

  var $container = $("article .gallery");
  /* $container.isotope({
    itemSelector: ".gallery-item",
    masonry: { columnWidth: $container.width() / 3 }
  });
 */

  
  $('a[href^="http"]').attr("target", function() {
    if (this.host == location.host) return "_self";
    else return "_blank";
  });

  /*  $(".download").click(function() {
    setTimeout(function() {
      var $offerOrBrochure = "";
      var $file = $(".cover[data-download-url]").attr("data-download-url");
      var $datastate = $(".cover[data-state]").attr('data-state');

      if ($datastate == "1") {
          $offerOrBrochure = "brochure";
      } else {
          $offerOrBrochure = "offer";
      }

      $('input[name="file"]').attr("value", $file);
      $('a.buttonSimple').attr('href', $file);
      $('input[name="id"]').attr('value', $offerOrBrochure);
      //console.log($file + "" + $offerOrBrochure);
    }, 2000);
  }); */
 
   /* var $container = $('.isotope');
    $container.isotope({
      masonry: { columnWidth: $container.width() / 4 }
    });  */

}); 